/* autoprefixer grid: autoplace */
import { graphql } from "gatsby";
import React, { useState } from "react";
import Cookies from "universal-cookie";

import { Routes } from "../constants/routes";
import Layout from "../components/layout-main";
import { trackButtonCta } from "../components/analytics";
import { scrollToElm, checkIfIE } from "../components/dom";
import { ContactForm, FormTypes } from "../components/form-contact";
import CalendlySection, {
  CalendlyEventTypes,
} from "../components/form-calendly";
import { keyframes } from "@emotion/react";

import Img from "gatsby-image";

/** @jsx jsx */
import { jsx, Grid, NavLink, Styled } from "theme-ui";
import { Faqs, WHAT_NOW_QUESTIONS } from "../components/faqs";
import { Helmet } from "react-helmet";
import {
  CAMPAIGN_ID,
  KEYWORD,
  AFTERWORD_COOKIE_PARAMS,
} from "../constants/cookies";

const tick = keyframes`
    0% {
        transform: translateY(0);
        visibility: visible;
    }

    100% {
        transform: translateY(-768%);
    }
`;

const titleTextSize = { fontSize: ["40px", "52px"] };

const BereavementContact = ({
  hideCalendly,
  formOutro,
  buttonText,
  emailPlaceholder,
  formType,
}) => {
  formType = formType || FormTypes.HERE_FOR_YOU;
  formOutro = formOutro || "Our team will reach out to you shortly.";

  let nestedForm = null;
  if (formType === FormTypes.HERE_FOR_YOU) {
    nestedForm = {
      buttonVariant: "blueButton",
      formType: FormTypes.HERE_FOR_YOU_PHONE,
      formTitle: "",
      formOutro: " ",
      emailPlaceholder: "Enter your phone number",
      hideImage: true,
      buttonText: "Submit",
      calendlyType: null,
    };
    formOutro +=
      " If you'd prefer a call or text, you can share your phone number.";
  }

  return (
    <ContactForm
      buttonVariant="blueButton"
      formType={formType}
      formTitle=""
      formOutro={formOutro}
      emailPlaceholder={emailPlaceholder}
      hideImage={true}
      buttonText={buttonText || "Contact Us"}
      nestedForm={nestedForm}
      calendlyType={!hideCalendly && CalendlyEventTypes.HERE_FOR_YOU}
      calendlyMessageOverride={
        "You can also schedule a time that works best for you."
      }
    />
  );
};

const BereavementPage = ({ data, location }) => {
  if (typeof window !== "object") {
    return null;
  }

  let urlParams = new URLSearchParams(window.location.search);
  const cookies = new Cookies();
  if (urlParams.get("campaign_id")) {
    cookies.set(
      CAMPAIGN_ID,
      urlParams.get("campaign_id"),
      AFTERWORD_COOKIE_PARAMS
    );
  }
  if (urlParams.get("keyword")) {
    cookies.set(KEYWORD, urlParams.get("keyword"), AFTERWORD_COOKIE_PARAMS);
  }

  let title = <>Expert advice when you need it most</>;

  return (
    <Layout
      location={location}
      pageTitle={"Expert advice when you need it most"}
      noIndex={false}
      buttonOverride={"#contactus"}
      buttonOverrideClickHandler={(event) => {
        scrollToElm("hero-header");
        let elm = document.querySelector("form input[type=email]");
        elm && elm.focus();
      }}
    >
      <section
        sx={{
          backgroundColor: "beige",
          paddingTop: "3rem",
        }}
      >
        <div className="aw-content">
          <Grid
            gap={[1, 2]}
            columns={["auto", "1fr 1fr"]}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "450px",
            }}
          >
            <div
              sx={{
                width: ["100%", "400px", "500px"],
                margin: "0 auto",
                textAlign: ["center", "left"],
                gridRow: [2, 1],
              }}
            >
              <Styled.h1
                id="hero-header"
                sx={{
                  fontWeight: "400",
                  fontSize: ["30px", "36px", "52px"],
                  lineHeight: "133% !important",
                  letterSpacing: "0.01em",
                  fontFamily: "Georgia, serif",
                }}
              >
                {title}
              </Styled.h1>
              <p
                sx={{
                  fontSize: "20px",
                  color: "#66645E",
                  letterSpacing: "-0.02em",
                  lineHeight: "133%",
                  marginTop: ["12px", "16px", "36px"],
                  marginBottom: ["12px", "16px", "36px"],
                  fontWeight: 400,
                  fontFamily: "Avenir",
                }}
              >
                Afterword is your free virtual care team to help you make
                important decisions with funeral planning & grief resources. Our
                services are free through the support of thousands of funeral
                homes that work with us.
              </p>

              <div
                id="form"
                sx={{
                  maxWidth: "400px",
                  margin: ["24px auto", "0"],
                  marginBottom: ["24px !important", "12px !important"],
                }}
              >
                <BereavementContact />
              </div>

              <p
                sx={{
                  fontSize: "16px",
                  color: "#66645E",
                  letterSpacing: "-0.02em",
                  lineHeight: "133%",
                  fontWeight: 500,
                  fontFamily: "Avenir",
                  marginBottom: "32px !important",
                }}
              >
                You can always call us at{" "}
                <a href="tel:+18552811851" target="_blank">
                  (855) 281-1851
                </a>
              </p>
            </div>

            <div sx={{ marginBottom: [0, "12px"], gridRow: [1, 1] }}>
              <Img
                title="memorial illustration"
                alt="memorial illustration"
                sx={{
                  width: ["80%", "100%"],
                  margin: ["0 auto"],
                }}
                fluid={data.service.childImageSharp.fluid}
              />
            </div>
          </Grid>
        </div>
      </section>

      <section id="unbiased-advice" className="aw-bg-blue">
        <div className="aw-content">
          <h2 sx={{ fontFamily: "Georgia", fontWeight: 200, ...titleTextSize }}>
            Our unbiased advice comes at no cost to you
          </h2>
          <Grid columns={["1fr", "1fr 1fr"]}>
            <div>
              <h4
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 600,
                  marginBottom: "8px",
                }}
              >
                Why is our service no cost to your family?
              </h4>
              <p sx={{ fontSize: "20px" }}>
                We're only paid by funeral homes if you choose to work with
                them. There are no extra fees added to your invoice. Our
                personalized guidance is free to you.
              </p>
            </div>

            <div>
              <h4
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 600,
                  marginBottom: "8px",
                }}
              >
                How are our recommendations unbiased?
              </h4>
              <p sx={{ fontSize: "20px" }}>
                Our advisors are not compensated based on which funeral home you
                choose. They're ready to help your family find the right home
                for you.
              </p>
            </div>
          </Grid>

          <p sx={{ marginTop: "24px", fontSize: "20px" }}>
            Our interests are aligned to provide the best outcome for your
            family and help you make a confident decision
          </p>
        </div>
      </section>

      <section id="how-it-works">
        <div class="aw-content">
          <Grid
            columns={["1fr", "1fr 300px", "3fr 2fr"]}
            gap={[2, 6]}
            sx={{ alignItems: "center" }}
          >
            <div>
              <h2
                sx={{
                  fontFamily: "Georgia",
                  fontWeight: 200,
                  marginBottom: 0,
                  ...titleTextSize,
                }}
              >
                How it works
              </h2>
              <h4
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  marginBottom: "8px",
                }}
              >
                Who's on your team
              </h4>
              <p sx={{ fontSize: "20px" }}>
                You'll be paired with a care team of funeral planners and death
                doulas with your best interest in mind. We're not paid to
                recommend specific funeral homes. You can think of us as your
                personal concierge.
              </p>

              <h4
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  marginBottom: "8px",
                  marginTop: "36px",
                }}
              >
                How we help
              </h4>
              <ul
                sx={{
                  listStyle: "none",
                  "& > li::before": {
                    content: "✔ ",
                    color: "#3740ff",
                    left: "0",
                  },
                  "& li": {
                    fontSize: "20px",
                  },
                }}
              >
                <li>Ongoing 1:1 support via video calls and messaging</li>
                <li>Personalized funeral home recommendations</li>
                <li>Compare quotes & transparent costs</li>
                <li>Support selecting the right provider</li>
                <li>Schedule initial appointments for you</li>
              </ul>

              <h4
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  marginBottom: "8px",
                  marginTop: "36px",
                }}
              >
                How we can do this for free
              </h4>
              <p sx={{ fontSize: "20px" }}>
                We're only paid by funeral homes when a family decides to work
                with them. We don't receive payments for recommendations, and
                there's no additional fee added to your invoice.
              </p>

              <div sx={{ maxWidth: ["100%", "400px"], marginBottom: "32px" }}>
                <BereavementContact
                  formType={FormTypes.HERE_FOR_YOU_PHONE}
                  emailPlaceholder={"Enter your phone number"}
                  buttonText={"Text me"}
                  hideCalendly={true}
                />
              </div>
            </div>

            <div
              sx={{
                marginLeft: ["auto", "auto"],
                marginRight: ["auto", "auto"],
                position: "relative",
              }}
            >
              <img
                src="/images/text-convo.png"
                sx={{
                  // position: "absolute", zIndex: 4, top: 0,
                  maxWidth: ["300px", "300px", "100%"],
                  margin: ["24px 0", "48px 0"],
                }}
              />
            </div>
          </Grid>
        </div>
      </section>

      <section
        id="informed-decisions"
        sx={{
          backgroundColor: "darkBlue",
          color: "white",
          height: ["auto", "550px"],
          paddingTop: 0,
          paddingBottom: 0,
          overflow: "hidden",
        }}
      >
        <div class="aw-content">
          <Grid columns={["none", "3fr 2fr"]} gap={[2, "128px"]}>
            <div
              sx={{
                height: ["auto", "550px"],
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <h2
                sx={{
                  fontFamily: "Georgia",
                  fontWeight: 200,
                  ...titleTextSize,
                }}
              >
                Feel in control{" "}
              </h2>
              <p sx={{ fontSize: "20px" }}>
                No one should feel rushed or pressured. We take the time to
                listen, so you always feel heard, seen, and supported. We put
                you back in control, without feeling taken advantage of.
              </p>

              <div
                sx={{
                  maxWidth: ["100%", "400px"],
                  marginTop: "24px",
                  marginBottom: ["8px", "32px"],
                }}
              >
                <BereavementContact />
              </div>
            </div>

            <div>
              <div
                sx={{
                  display: ["none", "inherit"],
                  overflow: "hidden",
                  height: "550px",
                  boxSizing: "content-box",
                  top: 0,
                  alignSelf: "stretch",
                  lineHeight: "38px",
                }}
              >
                <div
                  sx={{
                    display: "inline-block",
                    height: "80px",
                    lineHeight: "80px",
                    whiteSpace: "nowrap",
                    boxSizing: "content-box",
                    animationIterationCount: "infinite",
                    animationTimingFunction: "linear",
                    animationName: tick,
                    animationDuration: "15s",
                  }}
                >
                  {[
                    "Feel informed",
                    "Get transparency",
                    "Be heard",
                    "Gain confidence",
                    "Receive support",
                    "Find relief",
                    "Review options",
                    "Feel informed",
                    "Get transparency",
                    "Be heard",
                    "Gain confidence",
                    "Receive support",
                    "Find relief",
                    "Review options",
                  ].map((item) => {
                    return (
                      <p
                        sx={{
                          fontSize: "52px",
                          lineHeight: "1.5",
                          fontFamily: "Georgia",
                          marginBottom: "10px",
                          fontWeight: 200,
                        }}
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>

              <div
                sx={{
                  display: ["block", "none"],
                  marginBottom: "24px",
                  marginTop: "24px",
                }}
              >
                {[
                  "Feel informed",
                  "Get transparency",
                  "Be heard",
                  "Gain confidence",
                  "Receive support",
                  "Find relief",
                  "Review options",
                ].map((item) => {
                  return (
                    <p
                      sx={{
                        fontSize: "20px",
                        lineHeight: "1.5",
                        fontFamily: "Georgia",
                        marginBottom: "10px",
                        fontWeight: 200,
                      }}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </Grid>
        </div>
      </section>

      <section id="how-it-works">
        <div className="aw-content">
          <h2 sx={{ fontFamily: "Georgia", fontWeight: 200, ...titleTextSize }}>
            Your experience is important to us
          </h2>

          <Grid columns={["1fr", "1fr 1fr"]}>
            <div>
              <Img
                title="always have time for you"
                alt="always have time for you"
                sx={{
                  width: "80px",
                  height: "auto",
                }}
                fluid={data.here247.childImageSharp.fluid}
              />
              <h4>WE ALWAYS HAVE TIME FOR YOU</h4>
              <p sx={{ fontSize: "20px" }}>
                With 24/7 access to your care team and 1:1 video appointments,
                you'll never feel rushed.
              </p>
            </div>
            <div>
              <Img
                title="compassionate and judgment-free"
                alt="compassionate and judgment-free"
                sx={{
                  width: "80px",
                  height: "auto",
                }}
                fluid={data.hereSupport.childImageSharp.fluid}
              />
              <h4>COMPASSIONATE AND JUDGMENT-FREE</h4>
              <p sx={{ fontSize: "20px" }}>
                There is no “right way” to grieve. Our care team is here to help
                you create the memorial that's right for you and your family.
              </p>
            </div>
            <div>
              <Img
                title="accessible"
                alt="accessible"
                sx={{
                  width: "80px",
                  height: "auto",
                }}
                fluid={data.hereAccessible.childImageSharp.fluid}
              />
              <h4>ACCESSIBLE FOR EVERYONE</h4>
              <p sx={{ fontSize: "20px" }}>
                We believe everyone deserves access to the best services, so we
                search far and wide to find a funeral home that can accommodate
                your wishes.
              </p>
            </div>
            <div>
              <Img
                title="personalized and meaningful"
                alt="personalized and meaningful"
                sx={{
                  width: "80px",
                  height: "auto",
                }}
                fluid={data.herePersonalized.childImageSharp.fluid}
              />
              <h4>PERSONALIZED AND MEANINGFUL</h4>
              <p sx={{ fontSize: "20px" }}>
                Your someone's life was full and detailed and unique. We'll help
                you plan a memorial that feels like them.
              </p>
            </div>
          </Grid>
        </div>
      </section>

      <section
        id="on-your-own"
        sx={{
          backgroundColor: "beige",
          backgroundImage: "url(/images/header-splash.png)",
          backgroundPosition: "bottom",
          paddingBottom: 0,
          backgroundSize: ["100%", "100%"],
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="aw-content">
          <Grid
            gap={[1, 0]}
            columns={["auto", "3fr 1fr"]}
            sx={{ alignItems: ["center", "flex-start"] }}
          >
            <div sx={{ gridRow: [2, 1] }}>
              <h4
                sx={{ fontWeight: 500, fontSize: "24px", marginBottom: "24px" }}
              >
                Prefer not to speak to someone?
              </h4>
              <p sx={{ fontSize: "20px" }}>
                We've created an online tool to guide you through the process
              </p>
              <div
                sx={{
                  maxWidth: "400px",
                  marginTop: "12px",
                }}
              >
                <BereavementContact
                  hideCalendly={true}
                  formOutro="Our team will share the app with you shortly."
                  formType={FormTypes.HERE_FOR_YOU_APP}
                  buttonText="Sign Up"
                />
              </div>
            </div>

            <div sx={{ marginBottom: [0, "12px"] }}>
              <Img
                title="Afterword app logo"
                alt="Afterword app logo"
                sx={{
                  width: ["50%", "100%"],
                  margin: ["0 auto"],
                }}
                fluid={data.home.childImageSharp.fluid}
              />
            </div>
          </Grid>
        </div>
      </section>

      <Faqs
        id="faqs"
        faqsTitle="FAQS"
        variant="accordion"
        hideCalendly
        questions={WHAT_NOW_QUESTIONS}
      />
    </Layout>
  );
};

export default BereavementPage;

export const pageQuery = graphql`
  query {
    home: file(relativePath: { regex: "/header.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    service: file(relativePath: { regex: "/Service-Hero.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    venues: file(relativePath: { regex: "/Venues-dark.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hereAccessible: file(relativePath: { regex: "/here-accessible.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    herePersonalized: file(relativePath: { regex: "/here-personalized.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    here247: file(relativePath: { regex: "/here-247.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hereSupport: file(relativePath: { regex: "/here-support.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    map: file(relativePath: { regex: "/map.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effieHeadshot: file(relativePath: { regex: "/headshot-effie.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effieImage: file(relativePath: { regex: "/fe3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie1: file(relativePath: { regex: "/effie1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie2: file(relativePath: { regex: "/effie2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zackImage: file(relativePath: { regex: "/about-zack.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
